body {
  margin: 0;
  font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ecf0f1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.upload-box {
  border: 2px dashed #007bff;
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #f8f9fa;
  margin-bottom: 1rem;
}

.upload-box:hover {
  border-color: #0056b3;
  background-color: #e9ecef;
}

.upload-box.drag-active {
  border-color: #28a745;
  background-color: #e8f5e9;
}

.tips-list {
  padding-left: 1.5rem;
}

.tips-list li {
  margin-bottom: 0.5rem;
  color: #495057;
}

.feedback-section {
  border-left: 4px solid #007bff;
}

.feedback-section h4 {
  color: #007bff;
  margin-bottom: 1rem;
}

.card {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.container {
  /* padding-top: 1rem; */
  /* padding-bottom: 1rem; */
}

.padded-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.page-title {
  color: #0052a3;
  margin-bottom: 1.5rem;
  text-align: center;
  padding: 1rem 0;
}

main.flex-grow-1 {
  /* padding-top: 1rem; */
}

/* Admin Shared Styles */
.admin-editor {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-bottom: 2rem;
}

.admin-editor h2 {
  color: #0052a3;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.admin-editor .form-label {
  font-weight: 500;
  color: #495057;
  margin-bottom: 0.5rem;
}

.admin-editor .form-control,
.admin-editor .react-select__control {
  border-color: #ced4da;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.admin-editor .form-control:focus,
.admin-editor .react-select__control--is-focused {
  border-color: #0052a3;
  box-shadow: 0 0 0 0.2rem rgba(0, 82, 163, 0.25);
}

.admin-editor .btn-toolbar {
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.admin-editor .btn-primary {
  background-color: #0052a3;
  border-color: #0052a3;
}

.admin-editor .btn-primary:hover {
  background-color: #003d7a;
  border-color: #003d7a;
}

.admin-editor .btn-outline-primary {
  color: #0052a3;
  border-color: #0052a3;
}

.admin-editor .btn-outline-primary:hover {
  background-color: #0052a3;
  color: #ffffff;
}

.admin-editor .alert {
  margin-bottom: 1.5rem;
}

.admin-editor .image-upload {
  border: 2px dashed #ced4da;
  border-radius: 4px;
  padding: 2rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.admin-editor .image-upload:hover {
  border-color: #0052a3;
  background-color: rgba(0, 82, 163, 0.05);
}

.admin-editor .image-preview {
  max-width: 200px;
  margin: 1rem 0;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Quill Editor Video Styles */
.ql-editor iframe {
  max-width: 100%;
  width: 560px;
  height: 315px;
  margin: 1rem 0;
}

.ql-video {
  display: block;
  max-width: 100%;
  margin: 1rem auto;
}

.ql-editor .ql-video {
  width: 560px;
  height: 315px;
}

@media (max-width: 768px) {
  .ql-editor .ql-video,
  .ql-editor iframe {
    width: 100%;
    height: 56.25vw; /* maintain 16:9 aspect ratio */
  }
}

/* Rich Text Content Styles */
.rich-text-content {
  line-height: 1.6;
  color: #2c3e50;
}

.rich-text-content p {
  margin-bottom: 1rem;
}

.rich-text-content h1,
.rich-text-content h2,
.rich-text-content h3,
.rich-text-content h4,
.rich-text-content h5,
.rich-text-content h6 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
  color: #1a365d;
}

.rich-text-content ul,
.rich-text-content ol {
  margin-bottom: 1rem;
  padding-left: 2rem;
}

.rich-text-content li {
  margin-bottom: 0.5rem;
}

.rich-text-content a {
  color: #2563eb;
  text-decoration: none;
}

.rich-text-content a:hover {
  text-decoration: underline;
}

.rich-text-content img {
  max-width: 100%;
  height: auto;
  margin: 1rem 0;
  border-radius: 0.375rem;
}

.rich-text-content iframe {
  max-width: 100%;
  margin: 1rem 0;
  border-radius: 0.375rem;
  aspect-ratio: 16 / 9;
  width: 90%;
  max-width: 1000px;
}

.rich-text-content blockquote {
  border-left: 4px solid #e2e8f0;
  padding-left: 1rem;
  margin: 1rem 0;
  color: #4a5568;
  font-style: italic;
}

/* Company Detail Styles */
.company-detail .hover-card {
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.company-detail .hover-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.1) !important;
}

.company-detail .badge {
  font-weight: normal;
  font-size: 0.85rem;
  padding: 0.5rem 0.75rem;
}

.company-detail .company-hero {
  margin-top: -1rem; /* Compensate for any top spacing */
}

.company-detail .company-hero-image {
  transition: transform 0.3s ease-in-out;
}

.company-detail .course-image {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  transition: transform 0.3s ease-in-out;
}

.company-detail .hover-card:hover .course-image {
  transform: scale(1.02);
}

.company-detail .company-description {
  line-height: 1.8;
  color: #4a5568;
  font-size: 1.1rem;
}

.company-detail section {
  scroll-margin-top: 2rem;
}

.company-detail .company-logo {
  background: white;
  padding: 0.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,.05);
}

/* Job Card Company Link Styles */
.card .company-link {
  color: #495057;
  transition: color 0.2s ease-in-out;
}

.card .company-link:hover {
  color: #0052a3;
  text-decoration: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .company-detail .company-hero-image {
    height: 200px !important;
  }
  
  .company-detail .display-4 {
    font-size: 2rem;
  }
}